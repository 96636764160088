import React from "react";
import { useForm, Controller } from "react-hook-form";
import { TextField, Button } from "@material-ui/core";
import { useGlobalContext } from "../../context/ContextGlobal";



const AddDocumentacion = ({ sendData }) => {
  const { alertDialog } = useGlobalContext();
  const onSubmit = async (data) => {
    const max_size = 1000000;
    if (data.attachments[0].size > max_size) {
      alertDialog("error", "el tamaño del archivo excede el máximo permitido", "error");
      return false;
    }
    await sendData(data.attachments[0]);
  };

  const { handleSubmit, control } = useForm();
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="alert alert-info">
        <h3>
          Deberá adjuntar toda la documentación mencionada a continuación, en
          un solo documento en formato PDF con un tamaño máximo de 1 Megabyte, de lo contrario podrán ser excluidos del proceso:
        </h3>
        <ol>
          <li>Fotocopia de cédula de identidad vigente por ambos lados</li>
          <li>Currículum actualizado</li>
          <li>Certificado de título (Emitido por la entidad educacional)</li>
          <li>Certificado de cursos y/o postítulo, diplomado, magister, entre otros (de acuerdo a los
            requisitos de postulación)</li>
          <li>*Certificado de discapacidad (según corresponda)</li>
          <li>*Cuenta escrita (solo funcionarios/as PDI)</li>
        </ol>
      </div>

      <Controller
        name="attachments"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <TextField type={"file"} onChange={(e) => {
            field.onChange(e.target.files);
          }} required inputProps={{ accept: "application/pdf" }} />

        )}
      />
      <br />
      <Button className="m-2" type="submit" variant="contained" color="primary">Finalizar</Button>
    </form>
  );
};

export default AddDocumentacion;
